// Body
// --------------------------------------------------
body {
  font-family: $base-font;
  font-weight: normal;
  color: $text-color;
}

// Links
// --------------------------------------------------
a {
  text-decoration: none;
  color: $link-color;
  outline: 0;
  &:visited {
    color: $link-color;
  }
  &:hover {
    color: darken($link-color, 20);
  }
  &:focus {
    outline: thin dotted;
    color: darken($link-color, 20);
  }
  &:hover,
  &:active {
    outline: 0;
  }
}

#post, #page {
  .entry-content > p:not(.small) > a:not(.fancybox\.iframe) {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-radius: 3px;
    border-bottom-color: transparentize($deco-color, 0.4);
    border-start-start-radius: 4px;
    border-start-end-radius: 6px;
    &:hover,
    &:active,
    &:focus {
      border-bottom-color: $link-color;
    }
  }
}

.link-arrow {
  font-weight: 100;
  text-decoration: underline;
  font-style: normal;
}

// Headings
// --------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
}
h1 {
  @include font-rem(28);
  @media #{$medium} {
    @include font-rem(40);
  }
  margin: .5em 0;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration: none;
  color: $text-color;
  &:hover, &:active, &:focus {
    color: $link-color;
  }
  &:visited {
    color: $text-color;
  }
}

// Figures
// --------------------------------------------------
figcaption {
  @include font(14);
  line-height: 1.3;
  color: lighten($text-color, 10);
}

// Note text
// --------------------------------------------------
.notice {
  margin-top: 1.5em;
  padding: .5em 1em;
  text-indent: 0;
  @include font-rem(14);
  background-color: $body-color;
  border: 1px solid darken($body-color,20);
  @include rounded(3px);
}

// Blockquotes
// --------------------------------------------------
blockquote {
  font-family: $quote-font;
  line-height: 1.25em;
  @include font-rem(17);
  @media #{$medium} {
    margin: 0 -20px 0 -28px;
    padding: 0.1em 20px;
    border-left: 8px solid $border-color;
    border-radius: 14px;
    background-color: darken($body-color, 3);
    @include font-rem(18);
  }
}

// Abbrevisions
// --------------------------------------------------
abbr, acronym {
  &[title] {
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: $border-color;
    cursor: help;
  }
}

// Footnotes
// --------------------------------------------------
.entry-content .footnotes {
  ol, li, p {
    @include font-size(12);
    line-height: 1.5em;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  border-top: 1px solid $border-color;
  margin-top: 3em;
  padding: 2em 0;
  @media #{$small} {
    margin-top: 1.5em;
    padding: 1em 0;
  }
}

// Code
// --------------------------------------------------
tt, code, kbd, samp, pre {
  font-family: $code-font;
  font-weight: normal;
  color: $code-color;
  a {
    &:link {
      color: unset;
    }
    &:active,
    &:hover {
      color: darken($link-color, 20);
    }
    &:focus {
      outline: thin dotted;
      color: darken($link-color, 20);
    }
  }
}
p,
li {
  code {
    @include font-rem(14);
    line-height: 1.5;
    margin: 0 2px;
    padding: 2px 5px;
    border: 1px solid lighten($black, 90);
    background-color: lighten($black, 95);
    @include rounded(3px);
  }
}
pre {
  @include font-rem(14);
  line-height: 1.5;
  overflow-x: auto;
  code {color: lighten($text-color, 30);}
  &::-webkit-scrollbar {
    height: 12px;
    background-color: #34362e;
    border-radius: 0 0 4px 4px;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #6a6d5d;
    @include rounded(4px);
  }
}

// Address
// --------------------------------------------------
address {
  font-style: normal;
}
