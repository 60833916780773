// Typography
// --------------------------------------------------

$base-font         : 'open_sans', 'Open Sans', Arial, sans-serif;
$heading-font      : 'open_sanslight', 'Open Sans Light', 'Open Sans', Arial, sans-serif;
$header-font       : 'open_sanssemibold';
$caption-font      : $base-font;
$code-font         : 'Source Code Pro', 'Inconsolata-g', 'Inconsolata', monospace;
$alt-font          : serif;
$strong-font       : 'open_sanssemibold', 'Open Sans Semi-Bold', Arial, sans-serif;
$italic-font       : 'open_sans_italic', 'Open Sans Italic', Arial, sans-serif;
$strong-italic-font: 'open_sanssemibold_italic', 'Open Sans Semi-Bold Italic', Arial, sans-serif;
$quote-font        : 'Liberation SerifItalic', serif;

$doc-font-size     : 16;
$doc-line-height   : 26;

// Colors
// --------------------------------------------------
$base-color   : rgb(36, 38, 40);
$body-color   : #fefefe;
$text-color   : $base-color;
$comp-color   : $base-color;
$border-color : lighten($base-color,60);
$white        : #fefefe;
$black        : #000;
$link-color   : rgb(42, 100, 150);
$deco-color   : #ea2;
$code-color   : #c7254e;

$primary      : $base-color;
$success      : #5cb85c;
$warning      : #dd8338;
$danger       : #C64537;
$info         : #308cbc;
$alt-bg-color : #303538;

// MEDIA QUERIES
// --------------------------------------------------

$small      : "only screen and (max-width: 607px)";
$medium     : "only screen and (min-width: 608px)";
$large      : "only screen and (min-width: 840px)";
$highdensity: "only screen and (-webkit-min-device-pixel-ratio: 1.5)",
              "only screen and (-o-min-device-pixel-ratio: 3/2)",
              "only screen and (min-resolution: 144dpi)",
              "only screen and (min-resolution: 1.5dppx)";

// BOX SHADOW
$entry-shadow : 0 0.1px 0.5px -3px rgba(0, 0, 0, 0.028),
                0 0.3px 1.2px -3px rgba(0, 0, 0, 0.04),
                0 0.5px 2.3px -3px rgba(0, 0, 0, 0.05),
                0 0.9px 4px -3px rgba(0, 0, 0, 0.06),
                0 1.7px 7.5px -3px rgba(0, 0, 0, 0.072),
                0 4px 18px -3px rgba(0, 0, 0, 0.1)