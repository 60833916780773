html {
  height: 100%;
  background: $comp-color;
}

body {
  height: unset;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: $body-color;
}

// Main
// --------------------------------------------------
.entry,
.hentry {
  @include clearfix();
}
.entry-content {
  @include font-size(16);
  small, .small {
    @include font-size(12);
    line-height:1.2em;
  }
  em, i {
    &:not(.fa):not(.fas):not(.far):not(.fal):not(.fad):not(.fab) {
      font-family: $italic-font;
      strong, b {
        &:not(.fa):not(.fas):not(.far):not(.fal):not(.fad):not(.fab) {
          font-family: $strong-italic-font;
          font-style: normal;
        }
      }
    }
  }
  strong, b {
    &:not(.fa):not(.fas):not(.far):not(.fal):not(.fad):not(.fab) {
      font-family: $strong-font;
      em, i {
        &:not(.fa):not(.fas):not(.far):not(.fal):not(.fad):not(.fab) {
          font-family: $strong-italic-font;
          font-style: normal;
        }
      }
    }
  }
}

// Entry Header
// --------------------------------------------------
.entry-header {
  background: $body-color;
  width: 100%;
  overflow: hidden;
}
#index .entry-header {
  background: $comp-color;
  width: 100%;
  height: 100vh;
  .header-title {
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    top: 50%;
    margin: auto;
  }
}
#post-index {
  .entry-header {
    background: $comp-color;
    .header-title {
      h1 {
        margin-bottom: 25px;
        @media #{$large} {
          margin-bottom: 60px;
        }
      }
    }
  }
  &.index .entry-header {
    background: $comp-color;
    width: 100%;
    height: 100vh;
    .header-title {
      position: absolute;
      width: 100%;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      top: 50%;
      margin: auto;
    }
  }
}
.header-title {
  text-align: center;
  margin: 30px 0 0;
  @media #{$large} {
    margin-top: 76px;
  }
  padding: 0 20px;
  h1 {
    margin: .5em auto 0;
    max-width: 90%;
    font-weight: 700;
    @include font-rem(28);
    color: lighten($base-color,20);
    @media #{$medium} {
      @include font-rem(48);
    }
    @media #{$large} {
      @include font-rem(80);
    }
  }
  h2 {
    margin: 0 0 .5em;
    @include font-rem(18);
    color: lighten($base-color,40);
    @media #{$medium} {
      @include font-rem(32);
    }
  }
  h4 {
    margin: 0;
    color: lighten($base-color,40);
  }
}
#post-index .header-title h1 {
  color: lighten($base-color,40);
}
.feature .header-title {
  @media #{$medium} {
    position: absolute;
    top: 0;
    display: table;
    margin-top: 0;
    width: 100%;
    height: 380px;
    overflow: hidden;
    .header-title-wrap {
      display: table-cell;
      vertical-align: middle;
      margin: 0 auto;
      text-align: center;
    }
  }
  h1 {
    margin: 10px auto 0 auto;
    font-weight: lighter;
    @media #{$medium} {
      margin: 10px auto;
      color: $white;
      @include font-rem(60);
      text-shadow: 1px 1px 4px transparentize($base-color, 0.4);
      a {
        color: $white;
      }
    }
    @media #{$large} {
      @include font-rem(80);
    }
  }
  h2 {
    margin: 0 auto;
    @include font-rem(18);
    @media #{$medium} {
      &:last-child { margin: 0 auto 1em }
      @include font-rem(30);
      color: $white;
      a {
        color: $white;
      }
    }
  }
  h4 {
    margin: 0 auto;
    @media #{$medium} {
      &:last-child { margin: 0 auto 1em }
      color: $white;
      a {
        color: $white;
      }
    }
  }
}
.entry-image {
  position: relative;
  width: auto;
  height: 100%;
  max-height: 440px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($base-color, 0.6);
  }
  img {
    width: 100%;
  }
}
.entry-image .placeholder {
  height: 120px;
  background-size: cover;
  background-position: center;
  @media #{$medium} {
    height: 320px;
  }
  @media #{$large} {
    height: 440px;
  }
}


// Feature Image Caption
// --------------------------------------------------


.image-credit {
  margin: 0 auto;
  max-width: 440px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 15px;
  color: $white;
  background-color: transparentize($base-color, 0.5);
  @include font-rem(12);
  line-height: 1.3;
  text-align: right;
  @include border-radius(3px,0,0,3px);
  z-index: 10;
  @include clearfix();
  a {
    color: $white;
    text-decoration: none;
  }
  @media #{$medium} {
    max-width: 760px;
  }
  @media #{$large} {
    max-width: 960px;
  }
}

#post-index, #post .entry-content {
  .image-credit {
    position: relative;
    margin: 0 0 -35px auto;
    top: -35px;
    @media #{$medium} {
      max-width: 100%;
    }
    @media #{$large} {
      max-width: 100%;
    }
  }
  article .feature_image {
    width: 100%;
  }
}

// Single Post and Page
// --------------------------------------------------
.entry-meta {
  @include font-rem(13);
  section {
    padding: 10px 0;
  }
  section:first-child {
    padding-top: 20px;
  }
  section:last-child {
    padding-bottom: 15px;
  }
  #social-share, #meta-author {
    color: lighten($base-color,60);
    a {
      &, > code {
        color: lighten($base-color,60);
      }
      &:hover,
      &:active,
      &:focus {
        &, > code {
          color: lighten($base-color,30);
        }
      }
      &:visited {
        &, > code {
          color: lighten($base-color,60);
        }
      }
    }
    ul {
      margin: 0;
    }
    .vcard {
      &:before {
        content: " by ";
      }
      display: none;
    }
  }
}
.entry-title {
  @media #{$small} {
    margin: .3em 0;
  }
}
#post,
#page {
  .entry-content, .entry-meta {
    margin: 15px 2px 20px 2px;
    padding: 20px 15px;
    background-color: $white;
    //box-shadow: 0 0 0 1px transparentize($border-color, 0.9), 0 3px 3px transparentize($black, 0.9);
    @include box-shadow($entry-shadow);
    @include rounded(3px);
    @media #{$small} {
      box-shadow: none;
      padding: 10px 15px 2px;
      margin: 2px;
    }
    @media #{$medium} {
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px 30px;
    }
    @media #{$large} {
      max-width: 960px;
      margin: 50px auto 30px auto;
      padding: 30px 80px;
      > p:first-child {
        @include font-size(20);
      }
    }
  }
  .entry-content {
    a.disabled {
      pointer-events: none;
      text-decoration: line-through;
      border-bottom-style: none !important;
    }
  }
  .entry-meta {
    text-align: center;
    margin-bottom: 10px;
    box-shadow: none;
  }
}
#post {
  #to-top a.btn {
    border-color: lighten($base-color,70);
    &:hover, &:active, &:focus {
      border-color: $base-color;
    }
  }
}
#page {
  .entry-content {
    @media #{$large} {
      >p:first-child {
        font-size: 1em;
      }
    }
  }
  .entry-meta {
    margin: 10px auto 40px;
    @media #{$small} {
      margin: 2px auto 40px;
    }
    @media #{$large} {
      margin: 20px auto 60px;
    }
  }
}
.entry-tags {
  display: block;
  margin-bottom: 6px;
}
// Post Pagination Module
.pagination {
  margin: 0 10px 60px;
  text-align: center;
  span, a {
    margin: .2em 0;
  }
  ul {
    display: inline;
    margin: 0 8px;
    .btn {
      padding: .25em .5em;
    }
  }
  li {
    padding-left: 2px;
    padding-right: 2px;
  }
  .current-page {
    font-weight: 700;
  }
}

// Read More Module
.read-more {
  position: relative;
  margin: 40px 0 0;
  padding: 40px 15px 60px;
  background-color: lighten($base-color,70);
  //box-shadow: 0 0 0 1px transparentize($border-color, 0.9), 0 3px 3px transparentize($black, 0.9);
  border: 1px solid transparentize($border-color, 0.9);
  @include rounded(3px);
  @media #{$medium} {
    margin: 50px 0 0;
    padding: 50px 40px;
  }
  @media #{$large} {
    padding: 50px 80px;
    margin: 60px 0 0;
  }
  text-align: center;
  @include clearfix();
}
.read-more-header {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  height: 35px;
  a {
    @include btn();
  }
}
.read-more-content {
  @include font-size(16);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height:1.5em;
  // Dotted line underlines for links
  p > a,
  li > a {
    color: lighten($base-color,20);
    &:hover {
      color: $primary;
    }
  }
  h3 {
    margin: 0.5em 0;
    @include font-rem(24);
    a {
      color: $primary;
    }
    @media #{$medium} {
      @include font-rem(36);
    }
  }
  h4 {
    margin: 0.5em 0;
    @include font-rem(18);
    a {
      color: $primary;
    }
    @media #{$medium} {
      @include font-rem(24);
    }
  }
  p {
    margin: 0.5em 0;
  }
}
.read-more-list {
  border-top: solid 1px lighten($base-color,60);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.list-item {
  width: 100%;
  text-align: left;
  h4 {
    @include font-rem(18);
    margin-bottom: 0;
  }
  p {
    margin: 0.2em 0;
    @include font-rem(14);
  }
  span {
    display: block;
    @include font-rem(14);
    color: lighten($base-color,20);
  }
  @media #{$medium} {
    width: 50%;
    border: none;
    padding: 0;
    margin: 0;
    float: left;
    &:nth-child(2) {
      text-align: right;
    }
  }
}

// Post Index
// --------------------------------------------------
#post-index {
  #main {
    margin: 40px 2px 20px 2px;
    @media #{$small} {
      margin: 2px 2px 20px;
    }
    @media #{$medium} {
      margin-left: 20px;
      margin-right: 20px;
    }
    @media #{$large} {
      max-width: 800px;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
    }
    .tag {
      @include font-rem(16);
      padding: 0;
      span {
        float: left;
        padding: 0.1em 0.5em;
      }
      .count {
        background-color: lighten($base-color, 50);
        border-radius: 0 3px 3px 0;
      }
      &:hover, &:focus, &:active {
        .count {
          background-color: lighten($base-color, 30);
        }
      }
    }
    article {
      background-color: $white;
      //box-shadow: 0 0 0 1px transparentize($base-color, 0.9), 0 3px 3px transparentize($base-color, 0.9);
      box-shadow: $entry-shadow;
      @include rounded(3px);
      margin-bottom: 20px;
      padding: 25px 15px;
      @media #{$small} {
        box-shadow: none;
        margin-bottom: 2px;
      }
      @media #{$medium} {
        padding: 30px;
      }
      @media #{$large} {
        margin-bottom: 30px;
        padding: 50px 80px;
      }
      &:last-of-type {
        margin-bottom: 50px;
        @media #{$small} {
          margin-bottom: 32px;
        }
        @media #{$large} {
          margin-bottom: 60px;
        }
      }
    }
    section.hentry {
      margin: 20px 0;
      ul {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 70px;
        @media #{$small} {
          margin-bottom: 52px;
        }
        @media #{$large} {
          margin-bottom: 80px;
        }
      }
    }
  }
}

.entry-image-index {
	margin-top: -25px;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 10px;

	@media #{$medium} {
		margin-top: -30px;
		margin-left: -30px;
		margin-right: -30px;
		margin-bottom: 15px;
	}

	@media #{$large} {
		margin-top: -50px;
		margin-left: -80px;
		margin-right: -80px;
		margin-bottom: 20px;
	}

	img {
		border-radius: 3px 3px 0 0; // round image corners
    width: 100%;
	}
}

// Footer
// --------------------------------------------------
.footer-wrapper {
  @include clearfix();
  margin: 0 auto;
  padding: 8px 0px 20px;
  text-align: center;
  color: darken($border-color,20);
  background: $comp-color;
  border-top: 1px solid transparentize($border-color, 0.9);
  a {
    color: darken($border-color,10);
  }
  footer {
    max-width: 90%;
    margin: 0 auto;
    ul {
      margin: .5em 0;
    }
    #to-top {
	    position: relative;
	    top: -40px;
	    margin-bottom: -30px;
      a.btn {
        padding: 0 20px;
        color: lighten($base-color,70);
        background-color: $base-color;
        border-color: $body-color;
        &:hover, &:active, &:focus {
          background-color: $body-color;
          color: $base-color;
          border-color: $base-color;
        }
      }
    }
  }
  .copyright {
    text-align: left;
    border: 1px solid $border-color;
    padding: 0 1.5em;
    margin: 1em auto;
    max-width: 820px;
  }
}

// Social Share
// --------------------------------------------------
.socialcount {
  @include font-rem(16);
  font-weight: 700;
  li {
    padding-left: 10px;
    padding-right: 10px;
  }
  p > a,
  li > a {
    border-bottom-width: 0;
    &:hover {
      color: lighten($base-color,50);
    }
  }
}


// Browser Upgrade
// --------------------------------------------------
.upgrade {
  padding: 10px;
  text-align: center;
}

// Google Search
// --------------------------------------------------
#goog-fixurl {
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      list-style-type: none;
    }
  }
}
#goog-wm-qt {
  width: auto;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 8px 20px;
  display: inline-block;
  @include font-rem(14);
  background-color: $white;
  color: $primary;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: lighten($primary,50);
  @include rounded(3px);
}
#goog-wm-sb {
  @include btn();
}
