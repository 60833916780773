@import "fa/regular";
@import "fa/solid";
@import "fa/brands";
@import "fa/v4-shims";

.dl-menuwrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  -moz-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
  @media #{$medium} {
    position: fixed;
    max-width: 175px;
  }
  button.dl-trigger {
    top: 0;
    left: 0;
    background: $comp-color;
    border: none;
    width: 48px;
    height: 45px;
    text-indent: -900em;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    outline: none;
    @include border-radius(0,3px,0,0);
    //box-shadow: 0 12px 24px transparentize(@black, 0.65);
    @media #{$medium} {
      @include border-radius(0,3px,0,0);
    }
  }
  button.dl-trigger:hover,
  button.dl-active,
  ul {
    background: #aaa;
  }
  button.dl-trigger:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: $border-color;
    top: 10px;
    left: 16%;
    box-shadow:
      0 10px 0 $border-color,
      0 20px 0 $border-color;
  }
  button.dl-active {
    display: none;
  }
  ul {
    padding: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  li {
    position: relative;
    h4 {
      margin:0;
      padding: 15px 20px 0;
      color: rgba($white,.9);
    }
    p {
      margin: 0;
      padding: 15px 20px;
      @include font-rem(12);
      color: rgba($white,.8);
      a {
        display: inline;
        padding: 0;
        @include font-rem(12);
      }
    }
    i {
      display: inline-block;
      width: 2em;
    }
    a {
      display: block;
      position: relative;
      padding: 15px 20px;
      @include font-rem(14);
      line-height: 20px;
      font-weight: 300;
      color: $white;
      outline: none;
    }
    .dl-back > a {
      padding-left: 30px;
      background: rgba(0,0,0,0.2);
    }
    .dl-back:after,
    > a:not(:only-child):after {
      @extend %fa-icon;
      @extend .fas;
      position: absolute;
      line-height: 50px !important;
      top: 0;
      color: $white;
      speak: never;
      -webkit-font-smoothing: antialiased;
      content: "\f105";
    }
    .dl-back:after {
      left: 10px;
      color: rgba(212,204,198,0.5);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    > a:after {
      right: 10px;
      color: rgba(0,0,0,0.15);
    }
  }
  .dl-menu {
    margin: 0;
    position: absolute;
    width: 100%;
    max-width: 400px;
    opacity: 0;
    pointer-events: none;
    //box-shadow: 0 12px 24px transparentize(@black, 0.65);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    @media #{$medium} {
      @include border-radius(0,3px,0,0);
      overflow-y: auto;
      max-height: 650px;
    }
  }
  .dl-menu.dl-menu-toggle {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .dl-menu.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
  }
  .dl-submenu {
    @include rounded(3px);
    //box-shadow: 0 12px 24px transparentize(@black, 0.65);
  }
  /* Hide the inner submenus */
  li .dl-submenu {
    display: none;
  }
}
/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}
.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}
/* Animation classes for moving out and in */
.dl-menu.dl-animate-out {
  -webkit-animation: MenuAnimOut 0.4s ease;
  -moz-animation: MenuAnimOut 0.4s ease;
  animation: MenuAnimOut 0.4s ease;
}
@-webkit-keyframes MenuAnimOut {
  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}
@-moz-keyframes MenuAnimOut {
  100% {
    -moz-transform: translateZ(300px);
    opacity: 0;
  }
}
@keyframes MenuAnimOut {
  100% {
    transform: translateZ(300px);
    opacity: 0;
  }
}
.dl-menu.dl-animate-in {
  -webkit-animation: MenuAnimIn 0.4s ease;
  -moz-animation: MenuAnimIn 0.4s ease;
  animation: MenuAnimIn 0.4s ease;
}
@-webkit-keyframes MenuAnimIn {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}
@-moz-keyframes MenuAnimIn {
  0% {
    -moz-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateZ(0px);
    opacity: 1;
  }
}
@keyframes MenuAnimIn {
  0% {
    transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0px);
    opacity: 1;
  }
}
.dl-menuwrapper > .dl-submenu.dl-animate-in {
  -webkit-animation: SubMenuAnimIn 0.4s ease;
  -moz-animation: SubMenuAnimIn 0.4s ease;
  animation: SubMenuAnimIn 0.4s ease;
}
@-webkit-keyframes SubMenuAnimIn {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}
@-moz-keyframes SubMenuAnimIn {
  0% {
    -moz-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateZ(0px);
    opacity: 1;
  }
}
@keyframes SubMenuAnimIn {
  0% {
    transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0px);
    opacity: 1;
  }
}
.dl-menuwrapper > .dl-submenu.dl-animate-out {
  -webkit-animation: SubMenuAnimOut 0.4s ease;
  -moz-animation: SubMenuAnimOut 0.4s ease;
  animation: SubMenuAnimOut 0.4s ease;
}
@-webkit-keyframes SubMenuAnimOut {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}
@-moz-keyframes SubMenuAnimOut {
  0% {
    -moz-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -moz-transform: translateZ(-300px);
    opacity: 0;
  }
}
@keyframes SubMenuAnimOut {
  0% {
    transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    transform: translateZ(-300px);
    opacity: 0;
  }
}
/* No Touch Fallback */
.no-touch .dl-menuwrapper li a:hover {
  background: rgba(255,248,213,0.1);
}
/* No JS Fallback */
.no-js {
  .dl-trigger {
    display: none;
  }
  .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
  .dl-menuwrapper li .dl-submenu {
    display: block;
  }
  .dl-menuwrapper li.dl-back {
    display: none;
  }
  .dl-menuwrapper li > a:not(:only-child) {
    background: rgba(0,0,0,0.1);
  }
  .dl-menuwrapper li > a:not(:only-child):after {
    content: '';
  }
}
// Menu Color
.dl-menuwrapper button.dl-trigger:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
  background: $comp-color;
}
// Fix for IE
.dl-menu li {
  display: none
}
.dl-menuopen li {
  display: block
}