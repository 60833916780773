hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid #fff;
}

// Figures and images
// --------------------------------------------------
figure {
  margin: 0 0 10px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  @include clearfix();
  img {
    margin-bottom: 10px;
  }
  a {
    img {
      @include translate(0, 0);
      -webkit-transition-duration: 0.25s;
      -moz-transition-duration: 0.25s;
      -o-transition-duration: 0.25s;
      &:hover, &:active, &:focus {
        @include translate(0, -5px);
        //.box-shadow(0 0 10px transparentize($base-color, 0.8));
      }
    }
  }
  &.half {
    @media #{$large} {
      float: left;
      img {
        width: 310px;
        float: left;
        margin-right: 10px;
      }
      figcaption {
        clear: left;
      }
    }
  }
  &.third {
    @media #{$large} {
      img {
        width: 200px;
        float: left;
        margin-right: 10px;
      }
      figcaption {
        clear: left;
      }
    }
  }
}
svg:not(:root) {
  overflow: hidden;
}

// Buttons
// --------------------------------------------------
.btn {
  display: inline-block;
  // margin-bottom: 20px;
  padding: 8px 20px;
  @include font-rem(14);
  background-color: $primary;
  color: $white;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: $primary;
  @include rounded(3px);
  &:visited {
    color: $white;
  }
  &:not(span):hover, &:not(span):active, &:not(span):focus {
    background-color: $white;
    color: $primary;
  }
}
.btn-success {
  background-color: $success;
  color: $white;
  border-color: $success;
  &:visited {
    color: $white;
  }
  &:not(span):hover, &:not(span):active, &:not(span):focus {
    background-color: $white;
    color: $success;
  }
}
.btn-warning {
  background-color: $warning;
  color: $white;
  border-color: $warning;
  &:visited {
    color: $white;
  }
  &:not(span):hover, &:not(span):active, &:not(span):focus {
    background-color: $white;
    color: $warning;
  }
}
.btn-danger {
  background-color: $danger;
  color: $white;
  border-color: $danger;
  &:visited {
    color: $white;
  }
  &:not(span):hover, &:not(span):active, &:not(span):focus {
    background-color: $white;
    color: $danger;
  }
}
.btn-info {
  background-color: $info;
  color: $white;
  border-color: $info;
  &:visited {
    color: $white;
  }
  &:not(span):hover, &:not(span):active, &:not(span):focus {
    background-color: $white;
    color: $info;
  }
}

.tag {
  display: inline-block;
  margin: 4px;
  padding: 0.1em 0.5em;
  background-color: lighten($base-color,60);
  color: $white;
  @include rounded(3px);
  color: $white;
  text-transform: uppercase;
  &:visited {
    color: $white;
  }
}

a.tag {
  &:hover, &:active, &:focus {
    color: $white;
    background-color: lighten($base-color,30);
  }
  &:active, &:focus {
    @include translate(0, 1px);
    @include box-shadow(0 0 1px transparentize($base-color, 0.8));
  }
}

#post-index #main {
  h1, h2, h3, h4, h5, h6 {
    &.tag {
      font-size: 1.5rem;
    }
  }
}

// Well
// --------------------------------------------------
.well {
  padding: 20px;
  border: 1px solid $comp-color;
  @include rounded(4px);
}

// Animations
// --------------------------------------------------
.animated{-webkit-animation-fill-mode:both;-moz-animation-fill-mode:both;-ms-animation-fill-mode:both;-o-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:1s;-moz-animation-duration:1s;-ms-animation-duration:1s;-o-animation-duration:1s;animation-duration:1s;}.animated.hinge{-webkit-animation-duration:2s;-moz-animation-duration:2s;-ms-animation-duration:2s;-o-animation-duration:2s;animation-duration:2s;}@-webkit-keyframes fadeIn {
  0% {opacity: 0;}  100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@-o-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(.9);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }

  70% {
    -moz-transform: scale(.9);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }

  70% {
    -o-transform: scale(.9);
  }

  100% {
    -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }

  80% {
    -moz-transform: translateY(-10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }

  80% {
    -o-transform: translateY(-10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes drop {
  0% {
    -webkit-transform: translateY(-500px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes drop {
  0% {
    -moz-transform: translateY(-500px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes drop {
  0% {
    -o-transform: translateY(-500px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes drop {
  0% {
    transform: translateY(-500px);
  }
  100% {
    transform: translateY(0);
  }
}
.drop {
  -webkit-animation-name: drop;
  -moz-animation-name: drop;
  -o-animation-name: drop;
  animation-name: drop;
}

// jemoji
// --------------------------------------------------

img.emoji[alt] {
  font-size: 0;
  line-height: 0;
}