/*!
// ===========================================================
// based on HPSTR Jekyll Theme by Michael Rose
// ===========================================================
*/

// ROOT =======================================================
// Variables and mixins =======================================
@import "variables";
// MIXINS =====================================================
@import "mixins";
@import "grid";
// CSS Reset ==================================================
//@import "normalize";
@import "reset";
// Site wide styles (html, body, global classes) ==============
@import "site";
// TYPOGRAPHY =================================================
@import "typography";
// Pygments Syntax highlighting ===============================
@import "pygments";
// Coderay Syntax highlighting ===============================
@import "coderay";
// LAYOUT =====================================================
// Page level layout styles ===================================
@import "page";
// ELEMENTS ===================================================
// Figures, images, social media, other elements ==============
@import "elements";
// Drop down menu
@import "dl-menu";
// Webfonts and Icons =================================
@import "fa/fontawesome";
@import "fa/regular";
@import "fa/solid";
@import "fa/brands";
@import "fa/v4-shims";