// Selection
// --------------------------------------------------
::-moz-selection {
  background-color: lighten($base-color, 65%);
  color: $base-color;
  text-shadow: none;
}
::selection {
  background-color: lighten($base-color, 65%);
  color: $base-color;
  text-shadow: none;
}

// set-up the body font-size / line-height
body {
  margin-top: 0px + $doc-line-height;
  font-size: 0px + $doc-font-size;
}

// Global Classes
// --------------------------------------------------
.wrap {
  margin: 0 auto;
}
.all-caps {
  text-transform: uppercase;
}
.pull-left {
  float: left;
}
img.pull-left {
  margin: 7px 7px 0 0;
}
.pull-right {
  float: right;
}
img.pull-right {
  margin: 7px 0 0 7px;
}
.unstyled-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
    list-style-type: none;
  }
}
.inline-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
    list-style-type: none;
    display: inline;
    padding-right: .75em;
  }
}
.fullwidth {
  width: 100%;
}

.em1 {
  width: 1em;
}
.em2 {
  width: 2em;
}


// tables
// ––––––––––––––––––––––––––––––––––––––––––––––––––

table {
  overflow-x: auto;
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  border-bottom: .1rem solid $border-color;
  padding: .2rem .5rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

tr:nth-child(odd) {
  background-color: darken($body-color, 3);
}

thead tr:nth-child(odd) { background-color: $body-color; }

// Global Transition
// ---------------------------------------------------
@import "mixins";
p, q, span, figure, img,
h1, h2, h3, h4,
b, i, strong, em ,blockquote,
header, input, a, code {
  @include transition(all .2s ease);
  @include transition(color 0s);
}